import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class DailyCalendar extends Vue {
  @Prop({ required: false }) propDate!: string | Date
  @Prop({ required: false }) type!: any

  daysNames: Array<string> = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
  ]

  monthlyNames: Array<string> = [
    'ENE',
    'FEB',
    'MAR',
    'ABR',
    'MAY',
    'JUN',
    'JUL',
    'AGO',
    'SEP',
    'OCT',
    'NOV',
    'DIC',
  ]
  date: any = new Date()
  dayName = this.daysNames[new Date().getDay()]
  monthName = this.monthlyNames[new Date().getMonth()]
  events: any = []
  dateValue = 0
  //se manteniene un indice global para mejor control de las validaciones sobre las fechas
  dateIndex = -1
  dateType = ''
  weekNumber = 0
  weekRange = ''
  dayWeek = 1

  mounted() {
    if (this.type == 'weekly') {
      this.selectWeek(this.optainWeekNumber(new Date()))
    }
  }

  @Watch('type')
  propTypeChange() {
    switch (this.type) {
      case 'diary':
        break
      case 'weekly':
        break
      case 'monthly':
        this.dateType = 'month'

        break
    }
  }
  @Watch('propDate')
  propDateChange() {
    if (!(this.propDate instanceof Date)) this.date = new Date(this.propDate)
    else this.date = this.propDate
  }

  @Watch('date')
  dateChange() {
    if (this.type) {
      switch (this.type) {
        case 'diary':
          this.dateIndex = this.events.findIndex(
            (x: any) =>
              x.date.toISOString().split('T')[0] ==
              this.date.toISOString().split('T')[0],
          )
          this.dayName = this.daysNames[this.date.getDay()]
          break
        case 'weekly':
          this.dateIndex = this.events.findIndex(
            (x: any) => x.weekRange == this.weekRange,
          )

          break
        case 'monthly':
          this.dateIndex = this.events.findIndex(
            (x: any) =>
              x.date.toISOString().split('T')[0].substring(0, 7) ==
              this.date.toISOString().split('T')[0].substring(0, 7),
          )
          this.dateType = 'month'
          this.monthName = this.monthlyNames[this.date.getMonth()]
          break
      }

      if (this.dateIndex > -1) {
        this.dateValue = this.events[this.dateIndex].value
      } else {
        this.dateValue = 0
      }
    }
  }

  changeYear() {
    if (this.type == 'weekly') {
      this.date = []
      this.date.push(new Date())
      return this.date.push(new Date(this.date[0].getFullYear(), 12, 0))
    }
    this.date = new Date(this.date.getFullYear(), 12, 0)
  }

  optainWeekNumber(currentDate: Date) {
    const startDate = new Date(currentDate.getFullYear(), 0, 1)
    const days = Math.floor(
      (currentDate.valueOf() - startDate.valueOf()) / (24 * 60 * 60 * 1000),
    )

    const weekNumber = Math.ceil((currentDate.getDay() + 1 + days) / 7)

    return weekNumber
  }

  AddDate() {
    switch (this.type) {
      case 'diary':
        this.events.push({
          // date: new Date(this.date.toISOString().split('T')[0]),
          date: this.date,
          //type: 'is-info',
          value: this.dateValue,
          dayName: this.dayName,
        })
        break
      case 'weekly':
        this.events.push({
          // date: new Date(this.date.toISOString().split('T')[0]),
          date: new Date(
            this.weekRange.substring(0, this.weekRange.indexOf('to') - 1),
          ),
          //type: 'is-info',
          weekNumber: this.weekNumber,
          value: this.dateValue,
          weekRange: this.weekRange,
        })
        break
      case 'monthly':
        this.events.push({
          // date: new Date(this.date.toISOString().split('T')[0]),
          date: this.date,
          //type: 'is-info',
          value: this.dateValue,
          month: this.monthName,
        })

        break
    }
    this.dateIndex = this.events.length - 1
  }

  updateDate() {
    switch (this.type) {
      case 'diary':
        this.events[this.dateIndex] = {
          date: this.date,
          //type: 'is-danger',
          value: this.dateValue,
          dayName: this.dayName,
        }
        break
      case 'weekly':
        this.events[this.dateIndex] = {
          // date: new Date(this.date.toISOString().split('T')[0]),
          date: new Date(
            this.weekRange.substring(0, this.weekRange.indexOf('to') - 1),
          ),
          //type: 'is-info',
          weekNumber: this.weekNumber,
          value: this.dateValue,
          weekRange: this.weekRange,
        }
        break
      case 'monthly':
        this.events[this.dateIndex] = {
          // date: new Date(this.date.toISOString().split('T')[0]),
          date: this.date,
          //type: 'is-info',
          value: this.dateValue,
          month: this.monthName,
        }
        break
    }
    this.dateIndex = this.events.length - 1
  }

  removeDate() {
    this.events.splice(this.dateIndex, 1)
    this.dateIndex = -1
    this.dateValue = 0
  }

  getWeek(d: any) {
    const target = new Date(d.valueOf())
    const dayNr = (d.getDay() + 6) % 7
    target.setDate(target.getDate() - dayNr + 3)
    const firstThursday = target.valueOf()
    target.setMonth(0, 1)
    if (target.getDay() != 4) {
      target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7))
    }
    return 1 + Math.ceil((firstThursday - (target as any)) / 604800000)
  }

  selectWeek(weekNo: any) {
    this.weekNumber = weekNo

    const d1 = new Date()
    const numOfdaysPastSinceLastMonday = eval((d1.getDay() - 1) as any)
    d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday)
    const weekNoToday = this.getWeek(d1)
    const weeksInTheFuture = eval((weekNo - weekNoToday) as any)
    d1.setDate(d1.getDate() + eval((7 * weeksInTheFuture) as any))
    const rangeIsFrom =
      eval((d1.getMonth() + 1) as any) +
      '/' +
      d1.getDate() +
      '/' +
      d1.getFullYear()
    d1.setDate(d1.getDate() + 6)
    const rangeIsTo =
      eval((d1.getMonth() + 1) as any) +
      '/' +
      d1.getDate() +
      '/' +
      d1.getFullYear()

    this.weekRange = rangeIsFrom + ' to ' + rangeIsTo
    this.date = new Date(rangeIsFrom)

    this.date = []
    this.date.push(new Date(rangeIsFrom))
    this.date.push(new Date(rangeIsTo))
  }

  unselectableDates(day: any) {
    return 0 < day.getDate()
  }
}
